import { render, staticRenderFns } from "./vip.vue?vue&type=template&id=373f0a90&scoped=true"
import script from "./vip.vue?vue&type=script&lang=js"
export * from "./vip.vue?vue&type=script&lang=js"
import style0 from "./vip.vue?vue&type=style&index=0&id=373f0a90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "373f0a90",
  null
  
)

export default component.exports