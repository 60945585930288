import { get, post } from './request'

// export const baseUrl = 'http://localhost:18080/dictionary-project'
export const baseUrl = 'https://www.llznfy.com/dictionary-project'
//用户收藏
export const collect = params => post(baseUrl + '/official/consumer/collect', params)
//用户收藏列表
export const getCollectList = params => post(baseUrl + '/official/consumer/collect/list', params)
//用户取消收藏
export const disableCollect = params => post(baseUrl + '/official/consumer/disable/collect', params)
//用户取消收藏2
export const disableCollectWord = params => post(baseUrl + '/official/consumer/disable/collect/word', params)
//用户登录
export const login = params => post(baseUrl + '/official/consumer/login', params)
//用户注册
export const register = params => post(baseUrl + '/official/consumer/register', params)
//我的纠错
export const myCorrection = params => post(baseUrl + '/official/my/correction?consumerId=' + params)
//我的反馈
export const myFeedback = params => post(baseUrl + '/official/my/feedback?consumerId=' + params)
//购买记录
export const myOrder = params => post(baseUrl + '/official/my/order?consumerId=' + params)
//获取平台介绍
export const introduction = params => post(baseUrl + '/official/platform/introduction', params)
//开通会员
export const vip = params => post(baseUrl + '/official/purchase/vip', params)
//主动报错
export const reportCorrection = params => post(baseUrl + '/official/report/correction', params)
//主动反馈
export const feedback = params => post(baseUrl + '/official/report/feedback', params)
//搜索热度排行
export const searchHot = params => post(baseUrl + '/official/search/hot', params)
//单词搜索
export const searchWord = params => post(baseUrl + '/official/search/word/all', params)
//单词热度
export const wordHot = params => post(baseUrl + '/official/word/hot?word=' + params)
//获取用户信息
export const getUserInfo = () => get(baseUrl + '/sys/user/info')
//
export const wxlogin = params => get(baseUrl + '/wechat/wxlogin?sm=' + params)
// 单词ID精确搜索
export const exactSearchWord = params => post(baseUrl + '/official/exact/search/word', params)
// 查看pdf接口
export const readPaperContent = params => post(baseUrl + '/official/read/paper/content', params)
