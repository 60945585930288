<template>
  <div style="height: 481px">
    <div class="left">
      <img src="@/assets/pop_log_img@2x.png" />
    </div>
    <div class="right">
      <div class="main"
        v-if="currentShow === 'main'">
        <div class="QR-code-container">
          <div class="QR-title">
            <div class="title-text">微信扫码登录注册</div>
            <div class="title-text1">登陆开启词典新世界</div>
          </div>
          <div id="QR-code"
            class="QR-code"></div>
        </div>
        <!--        <div class="other">
                  <span @click="showLogin">手机号登录</span>
                  <span @click="showRegister">手机号注册></span>
        </div>-->
      </div>
      <div class="right-register"
        v-if="currentShow != 'main'">
        <div class="register-title">{{ currentShow === 'register' ? '注册账号' : '手机号登录' }}</div>
        <!-- <el-form ref="form" class="register-form" :model="form" :rules="rules">
          <el-form-item prop="mobile">
            <el-input
                placeholder="输入手机号"
                v-model="form.mobile" style="width: 288px; height: 40px"
                oninput="value=value.replace(/[^\d]/g,'');if(value.length > 11)value = value.slice(0, 11)"
            >
              <template slot="prepend">+86</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
                v-model="form.password"
                placeholder="输入6-30位密码"
                show-password
                style="width: 288px; height: 40px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
                class="registerBtn"
                style="width: 288px; heigtht: 40px; marigntop: 20px"
                @click="submitForm('form')"
            >
              {{ currentShow === 'register' ? '注册账号' : '登录' }}
            </el-button>
          </el-form-item>

          <div class="otherWay">
            <div style="float: left">
              <span @click="currentShow = 'main'">微信扫码登录</span>
            </div>
            <div style="float: right">
              {{ currentShow === 'register' ? '已有账号?' : '还没有账号?' }}
              <span
                  @click="currentShow === 'register' ? (currentShow = 'login') : (currentShow = 'register')"
              >{{ currentShow === 'register' ? '去登录' : '去注册' }}</span>
            </div>
          </div>
        </el-form>-->
      </div>
      <div class="footer">
        <div class="footer-text">
          登录即视为同意
          <span>《用户服务协议》</span>
          <span>《隐私政策》</span>
          <span>《授权许可协议》</span>
        </div>
        <div class="footer-text">
          如果您成为双语词典会员，成为会员即视为同意
          <span>《会员服务协议》</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueQrcode from "@xkeshi/vue-qrcode";
import { baseUrl, login, register } from "@/api/api.js";
import wx from "../api/wxLogin";

export default {
  name: "qecode",
  data() {
    return {
      qrcodeUrl: "www.baidu.com",
      currentShow: "main",
      rules: {
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { min: 11, message: "手机号格式不正确", trigger: "blur" }
        ]
      },
      form: {}
    };
  },
  components: {
    qrcode: VueQrcode
  },
  mounted() {
    //   appid=wxf187bb0c75ce8ed2
    // secret=242e3f06857d552238af7f281e21146b
    // redirect_uri=https://www.llznfy.com/dictionary-project/wechat/callback    
    var obj = new WxLogin({
      self_redirect: false,
      id: "QR-code",
      appid: "wxf187bb0c75ce8ed2",
      scope: "snsapi_login",
      redirect_uri: encodeURIComponent(baseUrl + "/wechat/callback"),
      style: "black",
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLm5vcm1hbFBhbmVse21hcmdpbi10b3A6IDE1cHg7fQouaW1wb3dlckJveCAucXJjb2RlIHt3aWR0aDogMjMwcHg7Ym9yZGVyLWNvbG9yOiNDNUUxRkY7fQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQ=="
    });
    // console.log(obj);
    // let params = {
    //   mobile: "13913891576",
    //   password: "123456"
    // }
    // login(params).then(res => {
    //   if (res.data.token) {
    //     localStorage.setItem("token", res.data.token);
    //     location.reload()
    //   }
    //   this.$emit("loginSuccess");
    // });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.currentShow === "login") {
            login(this.form)
              .then(res => {
                if (res.data.token) {
                  localStorage.setItem("token", res.data.token);
                }
                this.$emit("loginSuccess");
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            register(this.form).then(res => {
              this.currentShow === "main";
              alert("注册成功,请登录！");
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.left {
  width: 304px;
  height: 481px;
  float: left;

  img {
    width: 100%;
    height: 100%;
  }
}

.right {
  float: right;
  width: 446px;

  .main {
    .QR-code-container {
      width: 400px;
      height: 285px;

      .QR-title {
        text-align: center;
        margin: 10px 0;
        height: 50px;

        .title-text {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 28px;
        }

        .title-text1 {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 20px;
        }
      }

      .QR-code {
        text-align: center;
      }
    }

    .other {
      text-align: center;
      height: 17px;
      line-height: 17px;
      margin-top: 24px;

      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
        padding: 0 30px;
        cursor: pointer;
      }

      span:nth-child(1) {
        border-right: 1px solid #e8eaec;
      }
    }
  }

  .footer {
    width: 100%;
    height: 63px;
    background: #f8fafb;
    border-radius: 0px 0px 6px 0px;
    padding: 13px;
    box-sizing: border-box;

    .footer-text {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9ca3ac;
      line-height: 17px;

      span {
        color: #007aff;
        cursor: pointer;
      }
    }
  }

  .right-register {
    .register-title {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
      text-align: center;
      margin: 22px 0 60px 0;
    }

    .register-form {
      text-align: center;
      line-height: 56px;

      .registerBtn {
        width: 288px;
        height: 40px;
        margin-top: 20px;
        background-color: #126ad7;
        color: #fff;
      }

      .otherWay {
        width: 288px;
        height: 40px;
        margin: 32px auto 0;
        color: #b4b8bf;
        line-height: 20px;

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #007aff;
          cursor: pointer;
        }
      }
    }
  }

  .main,
  .right-login,
  .right-register {
    height: 418px;
    width: 446px;
    padding: 23px;
    box-sizing: border-box;
  }
}
</style>
