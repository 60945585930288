//1、引入axios
import axios from 'axios';
//2、创建axios的实例

let httpService = axios.create({
  // baseUrl: process.env.VUE_APP_BASE_API,
  // baseUrl:'http://localhost:80/dictionary-project',
  // timeout:5000
})
//3、axios的拦截--request
httpService.interceptors.request.use(config => {
  // 请求成功处理
  // config.headers.token = '0420a1db619eca4cd05c7e4b43089276'
  if (localStorage.getItem('token')) {//判断浏览器中的cookie中是否存在项目的token
    config.headers.token = localStorage.getItem('token')
  }
  return config;
}, err => {
  Promise.reject(err);// 请求错误处理
})

//4、axios的拦截--response
httpService.interceptors.response.use(response => {
  // token失效请重新登录
  if (response.data.code === 401) {
    localStorage.removeItem("token")
    localStorage.removeItem("userInfo")
  }
  return response;
}, err => {
  console.log(err)
  return Promise.reject(err);
})

//5、get请求的封装
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: 'get',
      params: params
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

//6、post请求封装  默认json格式:'Content-Type':'application/json',如果是文件上传，可以修改headers为 headers: { 'Content-Type': 'multipart/form-data' }
export function post(url, params = {}, headers = { 'Content-Type': 'application/json' }) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: 'post',
      data: params,
      headers: headers
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}
//7、将模块暴露
export default {
  get,
  post
}
