<template>
  <div>
    <img v-if="$route.path === '/'" src="@/assets/logo@3x.png" @click="toHome" class="index-nav-log" />
    <div v-else class="nav-log" @click="toHome">
      <img src="@/assets/log_logo.png" />
      <span>智能双语翻译云</span>
    </div>
    <div :class="$route.path === '/' ? 'indexTab' : 'tab'">
      <span
        v-for="tab in tabs"
        :key="tab.route"
        :class="tab.route === currentRoute ? 'active' : ''"
        @click="changeTab(tab.route)"
      >{{ tab.name }}</span>
    </div>
    <div class="header_right">
      <div class="cute_icon">
        <el-popover placement="bottom" width="306" popper-class="my-popover" v-model="hbVisiable" @hide="hideIcon">
          <heart-beat-rank @close="closeHBVisiable" />
          <svg
            @click="heartBeatClick"
            t="1635220930020"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2435"
            width="24"
            slot="reference"
          >
            <path
              d="M813.791929 239.816924c-126.014018 35.014468-149.063991 134.312008-140.930753 197.410092-89.868797-102.998839-86.187965-221.481324-86.187965-392.173914C298.468231 151.023622 365.467157 456.500988 356.824313 549.252439c-72.470545-57.847499-86.148056-196.061374-86.148056-196.061374-76.556607 38.387285-114.927519 140.912333-114.927519 224.089731 0 201.091948 167.227676 364.145557 373.482203 364.145557 206.253504 0 373.48118-163.05361 373.48118-364.145557C902.711098 457.758632 812.695968 402.612661 813.791929 239.816924z"
              p-id="2436"
              :fill="$route.path === '/searchPopular' ? '#fd6501' : '#8590A6'"
              ref="heartBeat"
            />
          </svg>
        </el-popover>
        <svg
          @click="collection"
          v-if="$store.state.isLogin"
          t="1635221573643"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="61085"
          width="21"
        >
          <path
            d="M999.424 477.184c22.528-24.576 30.72-57.344 20.48-88.064s-36.864-53.248-69.632-57.344l-235.52-34.816c-8.192 0-16.384-6.144-20.48-14.336L587.776 67.584C573.44 38.912 544.768 20.48 512 20.48s-61.44 18.432-75.776 47.104l-104.448 212.992c-4.096 8.192-12.288 14.336-20.48 16.384L73.728 331.776C40.96 333.824 14.336 356.352 4.096 389.12c-10.24 32.768 0 65.536 22.528 88.064l169.984 165.888c6.144 6.144 10.24 16.384 8.192 24.576L163.84 901.12c-6.144 32.768 8.192 63.488 32.768 83.968 14.336 10.24 30.72 16.384 51.2 16.384 14.336 0 28.672-4.096 38.912-10.24L497.664 880.64c8.192-4.096 18.432-4.096 26.624 0l210.944 110.592c12.288 6.144 26.624 10.24 40.96 10.24 18.432 0 34.816-6.144 51.2-16.384 26.624-20.48 38.912-51.2 34.816-83.968l-40.96-235.52c-2.048-8.192 2.048-18.432 8.192-24.576l169.984-163.84z m-264.192-20.48c-2.048 12.288-14.336 22.528-28.672 22.528h-6.144l-92.16-16.384c-28.672-6.144-53.248-24.576-63.488-51.2L512 325.632c-6.144-14.336 2.048-30.72 16.384-36.864 14.336-6.144 30.72 2.048 36.864 16.384l32.768 83.968c4.096 8.192 10.24 14.336 20.48 16.384l92.16 16.384c8.192 0 14.336 6.144 18.432 12.288 8.192 6.144 8.192 14.336 6.144 22.528z"
            p-id="61086"
            :fill="$route.path === '/collection' ? '#126ad7' : '#8590A6'"
          />
        </svg>
      </div>
      <span
        :class="[{'indexLogin': $route.path !== '/'}, 'login']"
        @click="dialogVisible = true"
        v-if="!$store.state.isLogin"
      >注册/登录</span>
      <el-dropdown class="hasLogin" v-else @command="handleCommand" trigger="click">
        <span class="el-dropdown-link">
          <el-avatar size="medium" :src="circleUrl"></el-avatar>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="myOrder">我的订单</el-dropdown-item>
          <el-dropdown-item command="feedback">问题反馈</el-dropdown-item>
          <el-dropdown-item command="exit">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="750px" :show-close="false" custom-class="my-dialog">
      <login @loginSuccess="loginSuccess" />
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVipVisible"
      width="860px"
      :show-close="false"
      custom-class="my-dialog"
      @close="closeVip"
    >
      <vip @close="
          dialogVipVisible = false;
          closeVip;
        " />
    </el-dialog>
  </div>
</template>

<script>
import heartBeatRank from './heartBeatRank.vue'
import Login from './login.vue'
import Vip from './vip.vue'
import userMenu from './userMenu'

export default {
  components: { heartBeatRank, Login, Vip, userMenu },
  name: 'dictHeader',
  data() {
    return {
      circleUrl: '',
      tabs: [
        // {
        //   id: 0,
        //   name: '词典',
        //   route: 'dict',
        // },
        {
          id: 0,
          name: '平台介绍',
          route: 'introduction',
        },
        {
          id: 1,
          name: '关于我们',
          route: 'about',
        },
        {
          id: 2,
          name: '联系我们',
          route: 'contact',
        },
        {
          id: 3,
          name: "编辑委员会",
          route: "operate",
        },
      ],
      currentRoute: 'dict',
      dialogVisible: false,
      dialogVipVisible: false,
      hbVisiable: false,
      menuVisiable: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.currentRoute = val.path.slice(1)
      },
      // 深度观察监听
      deep: true,
    },
    userInfo: {
      handler: function (val, oldVal) {
        if (val) {
          this.circleUrl = this.$store.state.userInfo.portrait
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  created() {
    this.stableTab()
  },
  methods: {
    handleCommand(command) {
      if (command === 'exit') {
        localStorage.removeItem("token")
        localStorage.removeItem("userInfo")
        this.$store.state.userInfo = {}
        location.reload()
        return
      }
      this.$router.push(command)
    },
    toHome() {
      this.$router.push('/')
    },
    changeTab(route) {
      this.currentRoute = route
      this.$router.push(route)
    },
    heartBeatClick() {
      this.$refs.heartBeat.attributes[3].nodeValue = '#fd6501'
    },
    vipClick() {
      this.$refs.vip.attributes[2].nodeValue = '#126ad7'
      this.dialogVipVisible = true
    },
    closeVip() {
      this.$refs.vip.attributes[2].nodeValue = '#8590A6'
    },
    collection() {
      this.$router.push('collection')
    },
    stableTab() {
      const currentRoute = window.location.pathname.replace('/', '')
      this.currentId = this.tabs.findIndex((tab) => {
        return tab.route === currentRoute
      })
    },
    closeHBVisiable() {
      this.hbVisiable = false
    },
    hideIcon() {
      if (this.$route.path === '/searchPopular') return
      this.$refs.heartBeat.attributes[3].nodeValue = '#8590A6'
    },
    showMenu() {
    },
    loginSuccess() {
      this.$store.state.isLogin = true
      this.dialogVisible = false
    }
  },
}

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}
</script>

<style lang="scss" scoped>
.index-nav-log {
  width: 200px;
  vertical-align: middle;
}

.nav-log {
  height: 34px;
  display: inline-block;
  text-align: left;
  font-size: 22px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #186ed7;
  line-height: 36px;
  cursor: pointer;

  img {
    width: 34px;
    vertical-align: top;
    font-size: 0px;
    margin-right: 8px;
  }
}

.indexTab {
  display: inline-block;
  margin-left: 52px;
  color: #fff;

  span {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    margin: 0 12px;
    cursor: pointer;
    padding: 3px 13px;
    display: inline-block;
  }

  .active {
    color: #126ad7;
  }
}

.tab {
  display: inline-block;
  margin-left: 52px;

  span {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    margin: 0 12px;
    cursor: pointer;
    padding: 8px 14px;
  }

  .active {
    color: #126ad7;
    background-color: #f3f7fb;
    border-radius: 20px;
  }
}

.header_right {
  float: right;
  margin-right: 42px;

  .cute_icon {
    height: 32px;
    border-right: 1px solid #e8eaec;
    line-height: 32px;
    display: inline-block;
    margin-right: 29px;

    svg {
      margin-right: 30px;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .login {
    padding: 8px 12px;
    background: #fff;
    border-radius: 4px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #126ad7;
    line-height: 20px;
    cursor: pointer;
  }

  .indexLogin {
    background: #126ad7;
    color: #fff;
  }

  .hasLogin {
    position: relative;
    width: 36px;
    height: 36px;
    display: inline-block;
    vertical-align: middle;
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    background-color: #eaeef5;
    cursor: pointer;
    svg {
      vertical-align: middle;
    }
  }

  .el-dropdown-link::after {
    position: absolute;
    top: 50%;
    left: 120%;
    transform: translateY(-50%);
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #8590a6;
  }
}
</style>
<style>
.el-popover.my-popover {
  padding: 0;
  border-radius: 8px;
}

.el-dialog.my-dialog {
  padding: 0;
  border-radius: 10px;
}

.el-dialog .el-dialog__header {
  padding: 0;
}

.el-dialog .el-dialog__body {
  padding: 0;
}
</style>
