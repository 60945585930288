<template>
  <div class="hbRank">
    <div style="padding: 21px 20px 10px 24px">
      <div class="title">词条搜索热度排行</div>
      <div class="list">
        <ul>
          <li v-for="(item, index) in list" :key="item.id">
            <div class="item">
              <span
                :class="[
                  { top1: index == 0 },
                  { top2: index == 1 },
                  { top3: index == 2 },
                  'icon',
                ]"
                >{{ index + 1 }}</span
              >
              <span class="content">{{ item.chineseCharacter }}</span>
              <span class="amount">{{ item.statistics }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="more" @click="more">
      <span>查看更多>></span>
    </div>
  </div>
</template>

<script>
import { searchHot } from '@/api/api.js'

export default {
  data() {
    return {
      list: []
    };
  },
  methods: {
    more() {
      this.$emit('close')
      this.$router.push('/searchPopular')
    }
  },
  created() {
    searchHot({pageSize:10,pageNum: 1}).then((res)=> {
      this.list = res.data.data.dataList
    })
  }
};
</script>

<style lang="scss" scoped>
.hbRank {
  width: 100%;
  height: 551px;

  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000;
    line-height: 22px;
    margin-bottom: 18px;
  }

  .list {
    height: 441px;
    overflow-y: scroll;
    overflow-x: hidden;
    .item {
      width: 100%;
      height: 24px;
      line-height: 24px;
      padding: 10px 0;

      .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: #f1f1f1;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #999999;;
      }
      .top1 {
        background: #fd6501;
      }
      .top2 {
        background: #f8b82f;
      }
      .top3 {
        background: #8590a6;
      }
      .top1,.top2,.top3 {
        color: #fff;
      }
      .content {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 24px;
        margin-left: 18px;
        color: #000;
      }

      .amount {
        float: right;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7f8792;
        line-height: 24px;
      }
    }
  }

  .more {
    height: 40px;
    background: rgba(218, 220, 224, 0.29);
    border-radius: 0px 0px 5px 5px;
    text-align: center;
    cursor: pointer;

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2D396F;
      line-height: 40px;
    }
  }
}

</style>