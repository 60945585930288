<template>
  <div class="rightBar">
    <div class="contact-container"
      @click="openDialog">
      <div class="dictionary-online">
        <img src="../assets/电子词典.png" />
        <span>电子<br />词典</span>
      </div>
    </div>
    <el-dialog width="1000px"
      title="电子词典"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      append-to-body>
      <iframe v-if="pdfUrl"
        :src="pdfUrl"
        height="800px"
        width="1000px"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { readPaperContent } from "@/api/api.js";
export default {
  data() {
    return {
      dialogVisible: false,
      pdfUrl: "",
    };
  },
  methods: {
    openDialog() {
      const userInfo = this.$store.state.userInfo;
      readPaperContent({
        consumerId: userInfo?.userId, // '1016113810454347776'
      }).then((res) => {
        const { data, code } = res.data
        if (code == 0) {
          this.dialogVisible = true;
          this.pdfUrl = data.paperContentUrl
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    closeDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBar {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 40%;
  .contact-container {
    width: 46px;
    background: #186ed7;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    box-sizing: border-box;
    > div {
      color: #fff;
      padding: 10px;
      text-align: center;
      font-size: 12px;
      cursor: pointer;
      display: block;
    }
    img {
      width: 22px;
    }
  }
}
/deep/.el-dialog {
  .el-dialog__header {
    height: 60px;
    line-height: 60px;
    background: #186ed7;
    padding: 0 20px;
    .el-dialog__title,
    .el-icon-close {
      color: #fff;
    }
    .el-icon-close:hover {
      opacity: 0.8;
    }
  }
  .el-dialog__body {
    display: flex;
    justify-content: center;
  }
}
</style>
