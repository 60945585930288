<template>
  <div id="app">
    <el-container :class="{ indexBg: $route.path === '/' }">
      <el-header height="87px" :class="{ otherPage: showBorder }">
        <dict-header ref="dictHeader" />
      </el-header>
      <el-main
        :class="[
          { greyBackground: showBackground },
          { whiteBackground: showWBackground },
        ]"
      >
        <router-view />
        <div class="navs" v-if="$route.meta.nav">
          <div
            v-for="(nav, index) in navs"
            :key="index"
            :class="['nav', { active: $route.path === '/' + nav.route }]"
          >
            {{ nav.name }}
          </div>
        </div>
        <leftViewIcon />
      </el-main>
    </el-container>
  </div>
</template>
<script>
import dictHeader from "@/components/dictHeader.vue";
import leftViewIcon from "@/components/leftViewIcon.vue";
import { getUserInfo, introduction } from "@/api/api.js";

export default {
  data() {
    return {
      showBorder: false,
      showBackground: false,
      showWBackground: false,
      navs: [
        {
          id: 0,
          name: "平台介绍",
          route: "introduction",
        },
        {
          id: 1,
          name: "关于我们",
          route: "about",
        },
        {
          id: 2,
          name: "联系我们",
          route: "contact",
        },
        {
          id: 3,
          name: "编辑委员会",
          route: "operate",
        },
      ],
    };
  },
  components: { dictHeader, leftViewIcon },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        val.meta.border ? (this.showBorder = true) : (this.showBorder = false);
        val.meta.background
          ? (this.showBackground = true)
          : (this.showBackground = false);
        val.meta.WBackground
          ? (this.showWBackground = true)
          : (this.showWBackground = false);

        if (oldVal.query && oldVal.query.sm) {
          this.getUser();
          location.reload();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  computed: {},
  created() {
    this.getUser();
    this.init();
  },
  methods: {
    getUser() {
      getUserInfo().then((res) => {
        if (res.data.code === 401) {
          this.$refs.dictHeader;
          console.log("登录已失效，请重新登录。");
        } else {
          this.$store.state.userInfo = res.data.user;
          this.$store.state.isLogin = true;
        }
      });
    },
    init() {
      introduction().then((res) => {
        this.$store.state.platform = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss">
#app {
  height: 100%;
  overflow: hidden;
  min-width: 1080px;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #d5d5d5;
  border-radius: 5px;
}

@font-face {
  font-family: "PoiretOne-Regular";
  src: url(./assets/PoiretOne-Regular.ttf);
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>

<style lang="scss" scoped>
.el-container {
  height: 100%;
}

.indexBg {
  background: url(./assets/bg_img@2x.png) no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.el-header {
  color: #65676b;
  line-height: 64px;
  padding: 23px 0 0 42px;
}

.el-header.otherPage {
  position: relative;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.el-main {
  position: relative;
  text-align: center;
  padding: 0;
  overflow: auto;
}

.el-main.greyBackground {
  background-color: #fafafb;
}

.el-main.whiteBackground {
  background-color: #fff;
}

.navs {
  position: fixed;
  top: 45%;
  right: 5%;
  width: 100px;
  text-align: right;

  .nav {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8590a6;
    line-height: 33px;
    height: 33px;

    span {
      display: inline-block;
      width: 4px;
      height: 4px;
      background-color: #8590a6;
      border-radius: 50%;
      vertical-align: middle;
      margin-left: 10px;
    }
  }

  .nav.active {
    font-weight: 600;
    color: #126ad7;
    font-size: 16px;
    margin-right: -6px;

    span {
      position: relative;
      width: 10px;
      height: 10px;
      background: #126ad7;
      right: 3px;
    }
  }
}
</style>
