import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/dict',
    name: 'Dict',
    component: () => import(/* webpackChunkName: "about" */ '../views/dict/index.vue'),
    meta: {WBackground: true}
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: () => import(/* webpackChunkName: "about" */ '../views/introduction/index.vue'),
    meta: {nav: true,WBackground: true}
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
    meta: {nav: true,WBackground: true}
  },
  {
    path: '/operate',
    name: 'Operate',
    component: () => import(/* webpackChunkName: "about" */ '../views/operate/index.vue'),
    meta: {nav: true,WBackground: true}
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact/index.vue'),
    meta: {nav: true,WBackground: true}
  },
  {
    path: '/searchPopular',
    name: 'SearchPopular',
    component: () => import(/* webpackChunkName: "about" */ '../views/searchPopular/index.vue'),
    meta: { border: true,background: true }
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import(/* webpackChunkName: "about" */ '../views/collection/index.vue'),
    meta: { border: true,background: true }
  },
  {
    path: '/myOrder',
    name: 'MyOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/myOrder/index.vue'),
    meta: { border: true,background: true }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "about" */ '../views/feedback/index.vue'),
    meta: { border: true,background: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
