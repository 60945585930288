<template>
  <div>
    <ul class="menu">
      <li v-for="(menu, index) in menus" :key="index" @click="goTo(menu.route)">
        <img :src="menu.url" />
        <span>{{ menu.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          name: "我的订单",
          url: require("@/assets/我的订单.png"),
          route: "myOrder"
        },
        {
          name: "问题反馈",
          url: require("@/assets/问题反馈-问题发起.png"),
          route: "feedback"
        },
        // {
        //   name: "退出",
        //   url: require("@/assets/退出.png"),
        //   route: "exit"
        // }
      ]
    };
  },
  methods:{
    goTo(route) {
      if(route === "exit") {
        this.$store.state.userInfo = {}
        localStorage.setItem("token","")
        this.$router.push('dict')
        location.reload()
      }
      this.$router.push(route)
    }
  }

};
</script>

<style lang="scss" scoped>
.menu {
  padding: 19px 0;
  box-shadow: 0px 0px 10px 0px rgba(10, 48, 89, 0.14);
  border-radius: 8px;

  li {
    width: 164px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    text-align: left;

    img {
      vertical-align: middle;
      margin: 0 18px 0 30px;
    }

    span {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }
  }
  li:hover {
    background: #eaeef5;
  }
}
</style>
